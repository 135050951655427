<template>
  <div class="container">
    <div class="classify_title">
      <div class="classify_title_item" @click="changeClassify(1)">
        <img
          :src="
            activeIndex == 1
              ? require('@/assets/images/myVideo_active@2x.png')
              : require('@/assets/images/myVideo@2x.png')
          "
        />
        <span class="classify_title_text" :class="{ active: activeIndex == 1 }"
          >我的预约</span
        >
        <span
          class="classify_title_num"
          :class="{ active: activeIndex == 1 }"
          >{{ namelist.AppointmentCount }}</span
        >
      </div>
      <div class="classify_title_item" @click="changeClassify(2)">
        <img
          :src="
            activeIndex == 2
              ? require('@/assets/images/myText_active@2x.png')
              : require('@/assets/images/myText@2x.png')
          "
        />
        <span class="classify_title_text" :class="{ active: activeIndex == 2 }"
          >我的收藏</span
        >
        <span
          class="classify_title_num"
          :class="{ active: activeIndex == 2 }"
          >{{ namelist.CollectionCount }}</span
        >
      </div>
      <div class="classify_title_item" @click="changeClassify(3)">
        <img
          :src="
            activeIndex == 3
              ? require('@/assets/images/myImage_active@2x.png')
              : require('@/assets/images/myImage@2x.png')
          "
        />
        <span class="classify_title_text" :class="{ active: activeIndex == 3 }"
          >直播中</span
        >
        <span
          class="classify_title_num"
          :class="{ active: activeIndex == 3 }"
          >{{ namelist.LiveCount }}</span
        >
      </div>
      <div class="classify_title_item" @click="changeClassify(4)">
        <img
          :src="
            activeIndex == 4
              ? require('@/assets/images/myMeeting_active@2x.png')
              : require('@/assets/images/myMeeting@2x.png')
          "
        />
        <span class="classify_title_text" :class="{ active: activeIndex == 4 }"
          >往期回顾</span
        >
        <span
          class="classify_title_num"
          :class="{ active: activeIndex == 4 }"
          >{{ namelist.LiveOverCount }}</span
        >
      </div>
    </div>
    <div class="content">
      <div class="listBox">
        <div class="list" v-for="(item, index) in list" :key="index">
          <a @click="$router.push('/contentPage?id=' + item.Id)">
            <div class="item_img">
              <img class="item_cover" :src="item.Thumbnail" />
              <img
                v-if="item.Type == 1"
                class="playIcon"
                src="@/assets/images/play_comm_icon@2x.png"
              />
            </div>
            <div class="item_content">
              <span class="item_title">{{ item.Title }}</span>
              <div class="item_info">
                <img src="@/assets/images/time@2x.png" />
                <span>{{ item.StartTime.substr(0, 10) }}</span>
              </div>
              <div class="item_info" v-if="item.LiveStatus > 0">
                <img src="@/assets/images/position@2x.png" />
                <span v-if="item.LiveStatus == 1">直播中</span>
                <span v-if="item.LiveStatus == 2">即将上线</span>
                <span v-if="item.LiveStatus == 3">已结束</span>
              </div>
              <div class="item_nums">
                <div class="nums">
                  <img src="@/assets/images/eye@2x.png" />
                  <span>{{ item.WatchCount }}</span>
                </div>
                <div class="nums">
                  <img src="@/assets/images/guanzhu@2x.png" />
                  <span>{{ item.CollectCount }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MyMeeting",
  data() {
    return {
      form: {
        customerid: "",
        utype: 1,
      },
      activeIndex: 1,
      list: [],
      namelist: [],
      loading: false,
      finished: false,
      error: false,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.name();
    this.onLoad();
  },
  methods: {
    changeClassify(val) {
      this.activeIndex = val;
      this.onLoad();
      this.name();
    },
    onLoad() {
      this.form.customerid = this.userInfo.Id;
      let that = this;
      that.list = [];
      that.loading = true;
      that.form.utype = this.activeIndex;
      that.url = "/Api/Api/Web/GetArticle_Behavior/GetListAtLive";
      that.$axios.post(that.url, that.form).then((res) => {
        console.log("cid" + this.form.customerid + "utype" + this.form.utype);
        console.log("123", res);
        that.list = res.Data;
        that.loading = false;
      });

      this.loading = false;
    },

    name() {
      this.form.customerid = this.userInfo.Id;
      let that = this;
      that.list = [];
      that.loading = true;
      that.form.utype = this.activeIndex;
      that.url = "/Api/Api/Web/GetArticle_Behavior/GetUserVideo";
      that.$axios
        .post(
          "/Api/Api/Web/GetArticle_Behavior/GetUserVideo?CustomerID=" +
            that.form.customerid
        )
        .then((res) => {
          // console.log("cid" + this.form.customerid + "utype" + this.form.utype);
          console.log("999", res);
          that.namelist = res.Data;
          that.loading = false;
        });

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  .classify_title {
    display: flex;
    background-color: rgba(216, 216, 216, 0.2);
    .classify_title_item {
      flex: 1;
      text-align: center;
      padding: 12px 0;
      overflow: hidden;
      img {
        width: 25px;
        height: 23px;
        display: block;
        margin: 0 auto;
      }
      span {
        display: block;
        color: #000000;
      }
      .classify_title_text {
        font-size: 14px;
        margin: 6px 0;
      }
      .classify_title_num {
        font-size: 15px;
      }
      .active {
        font-size: 15px;
        color: #ca001b;
        font-weight: bold;
      }
    }
  }
  .listBox {
    display: flex;
    flex-wrap: wrap;
  }
  .content {
    flex: 1;
    height: 0;
    overflow-y: scroll;
    padding: 20px 15px;
    box-sizing: border-box;
    .list {
      display: block;
      position: relative;
      width: 100%;
      margin: 0 5px 10px;
      background-color: #ffffff;
      border-radius: 7px;
      border: 1px solid rgba(151, 151, 151, 0.15);
      box-sizing: border-box;
      overflow: hidden;
      .item_img {
        position: relative;
        display: inline-block;
        width: 168px;
        border-radius: 8px;
        height: 2.6rem;
        overflow: hidden;
        vertical-align: middle;
        .item_cover {
          display: block;
          height: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 8px;
        }
        .playIcon {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 34px;
          height: 34px;
          transform: translate(-17px, -17px);
        }
      }
      .item_content {
        // flex: 1;
        display: inline-block;
        padding: 6px 11px;
        width: 3.8rem;
        vertical-align: middle;
        .item_title {
          font-size: 13px;
          color: #000000;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-wrap: break-word;
          margin-bottom: 5px;
          height: 1rem;
        }
        .item_info {
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #969696;
          img {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 12px;
            margin-right: 5px;
            vertical-align: middle;
          }
        }
        .item_nums {
          display: flex;
          height: 20px;
          .nums {
            display: flex;
            align-items: center;
            margin-right: 6px;
            img {
              width: 12px;
              margin-right: 2px;
            }
            span {
              font-size: 11px;
              color: #969696;
            }
          }
        }
      }
    }
  }
}
</style>