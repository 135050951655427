var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"classify_title"},[_c('div',{staticClass:"classify_title_item",on:{"click":function($event){return _vm.changeClassify(1)}}},[_c('img',{attrs:{"src":_vm.activeIndex == 1
            ? require('@/assets/images/myVideo_active@2x.png')
            : require('@/assets/images/myVideo@2x.png')}}),_c('span',{staticClass:"classify_title_text",class:{ active: _vm.activeIndex == 1 }},[_vm._v("我的预约")]),_c('span',{staticClass:"classify_title_num",class:{ active: _vm.activeIndex == 1 }},[_vm._v(_vm._s(_vm.namelist.AppointmentCount))])]),_c('div',{staticClass:"classify_title_item",on:{"click":function($event){return _vm.changeClassify(2)}}},[_c('img',{attrs:{"src":_vm.activeIndex == 2
            ? require('@/assets/images/myText_active@2x.png')
            : require('@/assets/images/myText@2x.png')}}),_c('span',{staticClass:"classify_title_text",class:{ active: _vm.activeIndex == 2 }},[_vm._v("我的收藏")]),_c('span',{staticClass:"classify_title_num",class:{ active: _vm.activeIndex == 2 }},[_vm._v(_vm._s(_vm.namelist.CollectionCount))])]),_c('div',{staticClass:"classify_title_item",on:{"click":function($event){return _vm.changeClassify(3)}}},[_c('img',{attrs:{"src":_vm.activeIndex == 3
            ? require('@/assets/images/myImage_active@2x.png')
            : require('@/assets/images/myImage@2x.png')}}),_c('span',{staticClass:"classify_title_text",class:{ active: _vm.activeIndex == 3 }},[_vm._v("直播中")]),_c('span',{staticClass:"classify_title_num",class:{ active: _vm.activeIndex == 3 }},[_vm._v(_vm._s(_vm.namelist.LiveCount))])]),_c('div',{staticClass:"classify_title_item",on:{"click":function($event){return _vm.changeClassify(4)}}},[_c('img',{attrs:{"src":_vm.activeIndex == 4
            ? require('@/assets/images/myMeeting_active@2x.png')
            : require('@/assets/images/myMeeting@2x.png')}}),_c('span',{staticClass:"classify_title_text",class:{ active: _vm.activeIndex == 4 }},[_vm._v("往期回顾")]),_c('span',{staticClass:"classify_title_num",class:{ active: _vm.activeIndex == 4 }},[_vm._v(_vm._s(_vm.namelist.LiveOverCount))])])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"listBox"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('a',{on:{"click":function($event){return _vm.$router.push('/contentPage?id=' + item.Id)}}},[_c('div',{staticClass:"item_img"},[_c('img',{staticClass:"item_cover",attrs:{"src":item.Thumbnail}}),(item.Type == 1)?_c('img',{staticClass:"playIcon",attrs:{"src":require("@/assets/images/play_comm_icon@2x.png")}}):_vm._e()]),_c('div',{staticClass:"item_content"},[_c('span',{staticClass:"item_title"},[_vm._v(_vm._s(item.Title))]),_c('div',{staticClass:"item_info"},[_c('img',{attrs:{"src":require("@/assets/images/time@2x.png")}}),_c('span',[_vm._v(_vm._s(item.StartTime.substr(0, 10)))])]),(item.LiveStatus > 0)?_c('div',{staticClass:"item_info"},[_c('img',{attrs:{"src":require("@/assets/images/position@2x.png")}}),(item.LiveStatus == 1)?_c('span',[_vm._v("直播中")]):_vm._e(),(item.LiveStatus == 2)?_c('span',[_vm._v("即将上线")]):_vm._e(),(item.LiveStatus == 3)?_c('span',[_vm._v("已结束")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"item_nums"},[_c('div',{staticClass:"nums"},[_c('img',{attrs:{"src":require("@/assets/images/eye@2x.png")}}),_c('span',[_vm._v(_vm._s(item.WatchCount))])]),_c('div',{staticClass:"nums"},[_c('img',{attrs:{"src":require("@/assets/images/guanzhu@2x.png")}}),_c('span',[_vm._v(_vm._s(item.CollectCount))])])])])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }